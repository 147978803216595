<template>
  <!-- !!!DEPRECATED!!! -->
  <div>
    <div class="container">
      <h2 class="strapi-product-gallery__title mb-0 mb-8">
        {{ component.title }}
      </h2>
      <div
        ref="carouselContainer"
        class="carousel slide carousel-fade product-gallery"
      >
        <div class="carousel-indicators">
          <button
            v-for="(image, index) in component.images"
            :key="image.id"
            :aria-label="`Slide ${index}`"
            :class="{ active: index === 0, 'aria-current': index === 0 }"
            :data-bs-slide-to="index"
            data-bs-target="#product-gallery"
            type="button"
          ></button>
        </div>

        <div class="carousel-inner">
          <div
            v-for="(image, index) in component.images"
            :key="image.id"
            :class="{ active: index === 0 }"
            class="carousel-item"
          >
            <strapi-image
              fluid
              :alt-fallback="`Slider Image ${index + 1}`"
              :image="image"
              image-class="d-block w-100"
            />
          </div>
        </div>
        <button
          aria-label="Previous Image"
          class="carousel-control-prev"
          data-bs-slide="prev"
          type="button"
          @click="prevImage"
        >
          <span class="rounded-circle py-3">
            <fa-icon
              class="text-rhino"
              :icon="faArrowLeft"
              aria-hidden="true"
            />
          </span>
        </button>

        <button
          aria-label="Next Image"
          class="carousel-control-next"
          data-bs-slide="next"
          type="button"
          @click="nextImage"
        >
          <span class="rounded-circle py-3">
            <fa-icon
              class="text-rhino"
              :icon="faArrowRight"
              aria-hidden="true"
            />
          </span>
        </button>

        <button
          v-if="showFullscreenButton"
          :aria-label="$t('frontend/fullscreen').toString()"
          class="product-gallery__fullscreen-btn btn btn-sm"
          type="button"
          @click="openModal"
        >
          <fa-icon :icon="faPlus" aria-hidden="true" />
        </button>
      </div>
    </div>

    <div
      ref="carouselModalContainer"
      class="modal fade"
      role="dialog"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body w-100">
            <button type="button" class="btn btn-sm" @click="closeModal">
              <fa-icon class="text-rhino" :icon="faMinus" aria-hidden="true" />
            </button>
            <strapi-image
              fluid
              alt-fallback="Slider Image"
              :image="component.images[currentSlideIndex]"
              image-class="d-block w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiProductGallery } from '~/apollo/types/types';
import type { Carousel } from 'bootstrap';
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faMinus,
} from '@fortawesome/pro-solid-svg-icons';
import useBootstrapCarousel from '~/composables/bootstrap/useCarousel';
import useBootstrapModal from '~/composables/bootstrap/useModal';
/**
 * @deprecated - use StrapiMediaGallery instead!
 */
export default defineComponent({
  name: 'StrapiProductGallery',
  props: {
    component: {
      type: Object as PropType<StrapiProductGallery>,
      required: true,
    },
  },
  setup() {
    // TODO: Just show the image with an strapi-image container if there is only one single
    // image. Same for other content types like videos.
    const currentSlideIndex = ref<number>(0);

    // carousel view
    const carouselContainer = ref<HTMLElement>();
    const setCurrentSlideIndex = (event) => {
      const { to } = event as unknown as Carousel.Event;
      currentSlideIndex.value = to;
    };
    const { nextItem: nextImage, prevItem: prevImage } = useBootstrapCarousel(
      carouselContainer,
      {
        interval: false,
      },
      setCurrentSlideIndex,
    );

    // full screen view
    const showFullscreenButton = ref<boolean>(false);
    const { width, height } = useWindowSize();
    const carouselModalContainer = ref<HTMLElement>();
    const { closeModal, openModal } = useBootstrapModal(carouselModalContainer);
    const setShowFullscreenButton = () => {
      if (carouselContainer.value == null) return;

      showFullscreenButton.value =
        // the gallery container width with offset is smaller than the window AND
        carouselContainer.value.clientWidth + 50 < width.value &&
        // the gallery container height with offset is smaller than the window
        carouselContainer.value.clientHeight + 50 < height.value;
    };
    const watchScreenWidthHandler = watchThrottled(
      () => width.value,
      setShowFullscreenButton,
      {
        throttle: 500,
      },
    );
    const watchScreenHeightHandler = watchThrottled(
      () => height.value,
      setShowFullscreenButton,
      {
        throttle: 500,
      },
    );

    onMounted(() => {
      setShowFullscreenButton();
    });

    onUnmounted(() => {
      watchScreenWidthHandler();
      watchScreenHeightHandler();
    });

    return {
      faArrowRight,
      faArrowLeft,
      faMinus,
      faPlus,

      carouselModalContainer,
      currentSlideIndex,
      carouselContainer,
      prevImage,
      nextImage,
      openModal,
      closeModal,
      showFullscreenButton,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';
.modal-dialog {
  max-width: 98vw;

  button {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    line-height: 25px;
    background-color: $gp-dark-mouse;
    color: $gp-mouse;
  }
}

.product-gallery {
  margin-top: 20px;
  margin-bottom: 50px;

  .carousel {
    &-inner {
      border-radius: 5px;
      z-index: 0;

      > button {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
        width: 30px;
        height: 30px;
        line-height: 20px;

        background-color: $gp-dark-mouse;
        color: $gp-mouse;
      }
    }
  }

  &__fullscreen-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    line-height: 20px;

    background-color: $gp-dark-mouse;
    color: $gp-mouse;
  }

  .carousel-control {
    &-prev,
    &-next {
      z-index: 0;
      > span {
        background-color: $gp-dark-mouse;
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
</style>
